export enum DirectConditionOperator {
  EXISTS = 'exists',
  DOES_NOT_EXIST = 'doesNotExist',
  EMPTY = 'empty',
  IS_NOT_EMPTY = 'isNotEmpty',
  TRUE = 'true',
  FALSE = 'false',
  IS_CHECKED = 'isChecked',
  IS_NOT_CHECKED = 'isNotChecked',
}

export enum ComparingConditionOperator {
  CONTAINS = 'contains',
  DOES_NOT_CONTAIN = 'doesNotContain',
  /**
   * @deprecated Use regex instead
   */
  MATCHES = 'matches',
  /**
   * @deprecated Use regex instead
   */
  DOES_NOT_MATCH = 'doesNotMatch',
  BEGINS = 'begins',
  DOES_NOT_BEGIN = 'doesNotBegin',
  ENDS = 'ends',
  DOES_NOT_END = 'doesNotEnd',
  EQUAL = 'equal',
  DOES_NOT_EQUAL = 'doesNotEqual',
  GREATER_THAN = '>',
  GREATER_OR_EQUAL = '>=',
  LESS_THAN = '<',
  LESS_OR_EQUAL = '<=',
  REGEX = 'regex',
  EQUALS_VAR = 'equalsVar',
}

export function isDirectOperator(operator: ConditionOperator): operator is DirectConditionOperator {
  return Object.values(DirectConditionOperator).includes(operator as DirectConditionOperator);
}

export function isComparingOperator(operator: ConditionOperator): operator is ComparingConditionOperator {
  return Object.values(ComparingConditionOperator).includes(operator as ComparingConditionOperator);
}

export type ConditionOperator = ComparingConditionOperator | DirectConditionOperator;
