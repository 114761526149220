import { MqttTopics } from './MQTT/MqttTopics';

export enum TriggerSource {
  BARCODE_SCAN = 'BarcodeScan',
  DATABASE_ITEM_CHANGE = 'DatabaseItemChange',
  FORM_CHANGE = 'FormChange',
  FUNCTION_CALL = 'FunctionCall',
  INCOMING_EMAIL = 'IncomingEmail',
  INCOMING_EVENT = 'IncomingEvent', // Webhook Connector
  LOCATION_CHANGE = 'LocationChange',
  MQTT_CONNECTOR = 'MqttConnector',
  SCHEDULE = 'Schedule',
  TASK_CHANGE = 'TaskChange',
  USER_CHANGE = 'UserChange',
  USER_LOGIN = 'UserLogin',
  USER_LOGOUT = 'UserLogout',
}

export const TriggerToRouteMap = Object.freeze({
  [TriggerSource.LOCATION_CHANGE]: MqttTopics.NOTIFICATION_LOCATION,
  [TriggerSource.BARCODE_SCAN]: MqttTopics.NOTIFICATION_BARCODE,
  [TriggerSource.TASK_CHANGE]: MqttTopics.TASK,
  [TriggerSource.INCOMING_EMAIL]: MqttTopics.EMAIL,
  [TriggerSource.DATABASE_ITEM_CHANGE]: MqttTopics.DATABASE_ITEM,
  [TriggerSource.FORM_CHANGE]: MqttTopics.FORM_RESULT,
  [TriggerSource.USER_CHANGE]: MqttTopics.USER,
  [TriggerSource.USER_LOGOUT]: MqttTopics.USER_LOGOUT,
  [TriggerSource.USER_LOGIN]: MqttTopics.USER_LOGIN,
  [TriggerSource.FUNCTION_CALL]: MqttTopics.FUNCTION_CALL,
  [TriggerSource.SCHEDULE]: MqttTopics.RULE_SCHEDULE,
  [TriggerSource.MQTT_CONNECTOR]: MqttTopics.CONNECTOR_MESSAGE_RECEIVED,
});

export enum TriggerType {
  INTERNAL_EVENT = 'internalEvent',
  EXTERNAL_EVENT = 'externalEvent',
}

export interface BaseRuleTrigger {
  type: TriggerType;
  source: string;
}

export interface SimpleRuleTrigger extends BaseRuleTrigger {
  type: TriggerType.INTERNAL_EVENT;
  source:
    | TriggerSource.USER_CHANGE
    | TriggerSource.USER_LOGIN
    | TriggerSource.USER_LOGOUT
    | TriggerSource.LOCATION_CHANGE
    | TriggerSource.BARCODE_SCAN
    | TriggerSource.TASK_CHANGE
    | TriggerSource.INCOMING_EMAIL
    | TriggerSource.DATABASE_ITEM_CHANGE
    | TriggerSource.FORM_CHANGE;
}

export interface ResourceRuleTrigger extends BaseRuleTrigger {
  source: TriggerSource.INCOMING_EVENT | TriggerSource.FUNCTION_CALL | TriggerSource.MQTT_CONNECTOR;
  resourceId: string;
}

export interface ScheduleRuleTrigger extends BaseRuleTrigger {
  type: TriggerType.INTERNAL_EVENT;
  source: TriggerSource.SCHEDULE;
  schedule: string;
}
export type RuleTrigger = SimpleRuleTrigger | ScheduleRuleTrigger | ResourceRuleTrigger;

export const TriggerSourcesWithoutLegacyConditions = [TriggerSource.FORM_CHANGE, TriggerSource.SCHEDULE];
