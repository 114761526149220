export enum AssignmentTypes {
  DATABASE = 'database',
  DELEGATE = 'delegate',
  DEVICE_LOCATION_CHANGE = 'deviceLocationChange',
  EMAIL = 'email',
  EVENT = 'event',
  FUNCTION = 'function',
  MESSAGE = 'message',
  MQTT_PUBLISH = 'mqttPublish',
  MQTT_SUBSCRIBE = 'mqttSubscribe',
  ROLES = 'roles',
  SKILLS = 'skills',
  TASK = 'task',
  TASK_STATUS_CHANGE = 'tasksStatusChange',
  UPDATE_TASK_VARIABLES = 'updateTaskVariables',
  WEBHOOK = 'webhook',
  WORKBENCH = 'workbench',
  WORKINSTRUCTION = 'workinstruction',
}
