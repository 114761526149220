export enum ModelNames {
  AccessRight = 'accessright',
  AnalyticsAvailability = 'analytics-availability',
  AnalyticsTask = 'analytics-tasks',
  Application = 'application',
  AnalyticsTaskStep = 'analytics-task-steps',
  Battery = 'battery',
  Connector = 'connector',
  ConnectorCallHistory = 'connector-call-history',
  Database = 'databases',
  DatabaseItem = 'databaseitems',
  DatabaseItemHistory = 'databaseitemhistory',
  DatabaseView = 'databaseViews',
  DatabaseViewMapping = 'databaseViewMappings',
  DeadLetteredQueueMessages = 'deadletteredqueuemessages',
  Device = 'device',
  DeviceApplication = 'deviceapplication',
  DeviceApplicationInstall = 'deviceapplicationinstall',
  DockerApplication = 'dockerapplication',
  Document = 'documents',
  DocumentBuilder = 'document-builder',
  DocumentInbox = 'document-inbox',
  DocumentVersion = 'document-versions',
  DocumentFormResult = 'document-form-result',
  EndPointLogs = 'endpointlogs',
  File = 'files',
  FileChunk = 'file-chunks',
  Function = 'functions',
  FunctionCallHistory = 'functioncallhistory',
  FunctionLog = 'functionlogs',
  FunctionVersion = 'FunctionVersion',
  Import = 'import',
  IncomingEmail = 'incomingemails',
  LicenseUsagePerDay = 'license-usage-per-day',
  LicenseUsagePerMonth = 'license-usage-per-month',
  LicenseUsagePerUserPerDay = 'license-usage-per-user-per-day',
  Location = 'locations',
  LocationParts = 'locationparts',
  Log = 'Log',
  Module = 'module',
  MQTT = 'mqtt',
  MyWorkUser = 'myworkuser',
  Note = 'notes',
  Notification = 'notifications',
  Project = 'projects',
  Reason = 'reason',
  RequestCache = 'requestcache',
  Role = 'role',
  Rule = 'rules',
  RuleTriggerHistory = 'ruletriggerhistory',
  ScanCode = 'scancodes',
  SentryEnvelope = 'sentry-envelopes',
  Settings = 'settings',
  Shift = 'shifts',
  ShiftPlan = 'shiftplans',
  Skill = 'skills',
  Task = 'tasks',
  TaskHistory = 'taskhistories',
  Token = 'token',
  Translation = 'translations',
  User = 'user',
  UserConnection = 'userconnection',
  UserLicenseUsage = 'userlicenseusage',
  UserLocationHistory = 'userlocationhistory',
  UserShift = 'usershifts',
  Workinstructions = 'workinstructions',
}

// reverse mapping of ModelNames
export const collectionSlugToName = Object.fromEntries(
  Object.entries(ModelNames).map(([modelName, collectionName]) => [collectionName, modelName]),
);

export const collectionsToSyncWithElasticsearch = [
  ModelNames.AnalyticsTaskStep,
  ModelNames.Database,
  ModelNames.DatabaseItemHistory,
  ModelNames.Function,
  ModelNames.LicenseUsagePerMonth,
  ModelNames.Location,
  ModelNames.LocationParts,
  ModelNames.Project,
  ModelNames.Role,
  ModelNames.Rule,
  ModelNames.Skill,
  ModelNames.Task,
  ModelNames.User,
  ModelNames.Workinstructions,
];
