import { AssignmentTypes } from '@workerbase/domain/rule';
import { RulePOSTBase } from 'services/types/Rule';
import { DatabaseColumnTypes } from '@workerbase/domain/database';

export enum DatabaseAction {
  CREATE_OR_UPDATE = 'CREATE_OR_UPDATE',
  DELETE = 'DELETE',
}

export enum DatabaseItemAction {
  IGNORE = 'IGNORE',
  REMOVE = 'REMOVE',
  REPLACE = 'REPLACE',
  INCREASE = 'INCREASE',
  DECREASE = 'DECREASE',
}

export interface ColumnAction {
  action: DatabaseItemAction;
  name: string;
  slug: string;
  type: DatabaseColumnTypes;
  value: string;
  _id: string;
}

export interface ColumnActionGET {
  action: DatabaseItemAction;
  value: string;
  _id: string;
}

export interface RuleDatabaseAction {
  databaseId: string;
  databaseAction: DatabaseAction;
  itemId: string;
  payload: ColumnAction[];
}

export interface RuleDatabasePOST extends RulePOSTBase {
  assignmentType: AssignmentTypes.DATABASE;
  database: string;
  databaseAction: DatabaseAction;
  databaseUniqueID: string; // item unique id
  databasePayload: ColumnAction[];
}
