import { TriggerType } from '@workerbase/types/RuleTrigger';

export { TriggerType } from '@workerbase/types/RuleTrigger';

export interface TriggerPayloadPOST {
  source: string;
  type: TriggerType;
  resourceId?: string;
}

interface TriggerBase {
  name: string;
  description: string;
  source: string;
  type: TriggerType;
  disabled: boolean;
  disabledReason?: string;
}

export interface TriggerInternalEvent extends TriggerBase {
  type: TriggerType.INTERNAL_EVENT;
  schedule?: string;
}

interface TriggerExternalEvent extends TriggerBase {
  type: TriggerType.EXTERNAL_EVENT;
  resourceId: string;
}

export type Trigger = TriggerInternalEvent | TriggerExternalEvent;
