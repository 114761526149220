/* eslint-disable complexity */
import { DatabaseColumnTypes } from '@workerbase/domain/database';
import { AssignmentTypes } from '@workerbase/domain/rule';
import { NotificationTypes } from '@workerbase/domain/common';
import { SelectedCondition } from '@workerbase/types/RuleCondition/SelectedCondition';
import {
  AutoAcceptTaskAnnouncement,
  AwarenessTaskAnnouncement,
  NoneTaskAnnouncement,
  NotificationTaskAnnouncement,
  TaskAnnouncement,
  TaskAnnouncementType,
} from '@workerbase/domain/task';
import { splitVariables } from '@workerbase/utils/splitVariables';
import {
  AllLocationSetting,
  DefinedLocationSetting,
  EventOriginLocationSetting,
  GlobalLocationSettings,
  LocationSetting,
  LocationType,
  VariableLocationSetting,
} from 'services/types/Location';
import { Payload, PayloadPOST, RequestMethod, RequestPayload, RequestPayloadFormat } from 'services/types/Request';
import { Rule, RuleAction, RuleGET, RulePOST, RulePOSTBase, RuleTrigger } from 'services/types/Rule';
import {
  DatabaseAction,
  RuleDatabaseAction,
  RuleDatabasePOST,
  RuleDeviceLocationChangeAction,
  RuleEmailAction,
  RuleEmailPOST,
  RuleEventAction,
  RuleEventPOST,
  RuleFunctionAction,
  RuleFunctionActionNetwork,
  RuleFunctionPOST,
  RuleMessageAction,
  RuleMessagePOST,
  RuleMqttPublishAction,
  RuleMqttSubscribeAction,
  RuleRequestAction,
  RuleRequestPOST,
  RuleRolesAction,
  RuleSkillsAction,
  RuleTaskAction,
  RuleTaskPOST,
  RuleTaskStatusChangeAction,
  RuleTaskStatusChangePOST,
  RuleUpdateTaskVariablesAction,
  RuleWebhookAction,
  RuleWebhookPOST,
  RuleWorkinstructionAction,
  RuleWorkinstructionPOST,
} from 'services/types/Rule/Actions';
import { Recipient, RecipientPOST, RecipientTypes, RecipientWithoutCaller } from 'services/types/Rule/Recipient';
import { Trigger, TriggerType } from 'services/types/Rule/Trigger';
import { TaskStatus } from 'services/types/Task';

function generateRuleTriggerFromRuleGET(trigger: RuleGET['trigger'], event?: string): RuleTrigger {
  if (trigger.type === TriggerType.EXTERNAL_EVENT) {
    return {
      source: trigger.source,
      resourceId: trigger.resourceId || event || '',
    };
  }
  return {
    source: trigger.source,
    schedule: trigger.schedule,
  };
}

function generateRecipientFromRuleGET(rule: RuleGET): Recipient {
  if (rule.selfinstruction) {
    return {
      type: RecipientTypes.CALLER,
      selfinstruction: true,
    };
  }

  if (rule.isAssignedUserVar) {
    return {
      type: RecipientTypes.VARIABLES,
      variables: rule.assignedUserVar?.map((variable) => `$${variable}`).join(' ') || '',
    };
  }
  if (rule.isUserMessage || rule.isUserTask) {
    return {
      type: RecipientTypes.USER,
      isAssignedUserVar: false,
      assignedUser: rule.assignedUser || '',
    };
  }

  return {
    type: RecipientTypes.ROLES,
    roles: rule.roles || [],
  };
}

function generateGlobalLocationSettingsFromRuleGET(rule: RuleGET): GlobalLocationSettings | undefined {
  if (rule.notifyLocationIsVar) {
    return {
      isVariable: true,
      locationIdVariable: rule.notifyLocationVar || '',
    };
  }

  if (!rule.notifyLocations) {
    return {
      isVariable: false,
      locationSettings: [],
    };
  }

  return {
    isVariable: false,
    locationSettings: rule.notifyLocations.map((location) => {
      if (location.type === LocationType.ALL) {
        return {
          type: LocationType.ALL,
          levelId: location.level || '',
        } as AllLocationSetting;
      }
      if (location.type === LocationType.EVENT_ORIGIN) {
        return {
          type: LocationType.EVENT_ORIGIN,
          levelId: location.level || '',
        } as EventOriginLocationSetting;
      }
      if (location.type === LocationType.DEFINED_LOCATION) {
        return {
          type: location.type,
          partId: location.partId || '',
          levelId: location.level || '',
        } as DefinedLocationSetting;
      }

      return {
        type: LocationType.VARIABLE,
        variable: location.variable || '',
        levelId: location.level || '',
      } as VariableLocationSetting;
    }),
  };
}

function generatePayloadFromRuleGET(rule: RuleGET): Payload {
  return {
    useCustomPayload: rule.useCustomPayload || false,
    format: rule.reqPayloadFormat || RequestPayloadFormat.JSON,
    reqPayloads: rule.reqPayload || [],
    rootName: rule.reqPayloadRootName || undefined,
  };
}

// eslint-disable-next-line complexity
function generateRuleActionFromRuleGET(rule: RuleGET): RuleAction | undefined {
  if (rule.noAction === true) {
    return undefined;
  }

  switch (rule.assignmentType) {
    case AssignmentTypes.WORKINSTRUCTION:
      return {
        type: AssignmentTypes.WORKINSTRUCTION,
        task: {
          headline: rule.task?.headline || '',
          title: rule.task?.title || '',
          description: rule.task?.description || '',
          priority: rule.task?.priority || '3',
          announcement: normalizeTaskAnnouncementFormValues(rule.task?.announcement),
          rejectReasons: rule.rejectReasons?.map((rejectReason) => rejectReason.text || '') || [],
        },
        skills: rule?.assignment?.skills,
        workinstructionId: rule.workinstructions || '',
        recipient: generateRecipientFromRuleGET(rule),
        globalLocationSettings: generateGlobalLocationSettingsFromRuleGET(rule),
        taskAdvancedSettings: {
          taskExternalId: rule.taskExternalId || '',
          initialStepObjectId: rule.initialStepObjectId || null,
          announcementDelay: rule.announcementDelay !== null ? rule.announcementDelay : null,
          reannouncePeriod: rule.reannouncePeriod !== null ? rule.reannouncePeriod : null,
          ttl: rule.ttl !== null ? rule.ttl : null,
          dueInSeconds: rule.dueInSeconds !== null ? rule.dueInSeconds : null,
          autostart: typeof rule.autostart === 'undefined' ? true : rule.autostart,
          suspendTaskWhenNoUserAvailable: !!rule.suspendTaskWhenNoUserAvailable,
          suspendTaskWhenNoUserIsAssignable: !!rule.suspendTaskWhenNoUserIsAssignable,
          notificationForce: !!rule.notificationForce,
        },
        connectedTo: rule.connectedTo || [],
      };
    case AssignmentTypes.TASK:
    case AssignmentTypes.DELEGATE:
      return {
        type: rule.assignmentType,
        skills: rule?.assignment?.skills,
        task: {
          headline: rule.task?.headline || '',
          title: rule.task?.title || '',
          description: rule.task?.description || '',
          priority: rule.task?.priority || '3',
          announcement: normalizeTaskAnnouncementFormValues(rule.task?.announcement),
          rejectReasons: rule.rejectReasons?.map((rejectReason) => rejectReason.text || '') || [],
        },
        recipient: generateRecipientFromRuleGET(rule),
        globalLocationSettings: generateGlobalLocationSettingsFromRuleGET(rule),
        taskAdvancedSettings: {
          taskExternalId: rule.taskExternalId || '',
          initialStepObjectId: rule.initialStepObjectId || null,
          announcementDelay: rule.announcementDelay,
          reannouncePeriod: rule.reannouncePeriod,
          ttl: rule.ttl,
          dueInSeconds: rule.dueInSeconds,
          autostart: typeof rule.autostart === 'undefined' ? true : rule.autostart,
          suspendTaskWhenNoUserAvailable: !!rule.suspendTaskWhenNoUserAvailable,
          suspendTaskWhenNoUserIsAssignable: !!rule.suspendTaskWhenNoUserIsAssignable,
          notificationForce: !!rule.notificationForce,
        },
      };
    case AssignmentTypes.MESSAGE:
      return {
        type: AssignmentTypes.MESSAGE,
        message: rule.message || '',
        timeout: rule.timeout ? { seconds: rule.timeout.seconds } : undefined,
        recipient: generateRecipientFromRuleGET(rule),
        globalLocationSettings: generateGlobalLocationSettingsFromRuleGET(rule),
        announcementDelay: rule.announcementDelay || null,
      };
    case AssignmentTypes.EMAIL:
      return {
        type: AssignmentTypes.EMAIL,
        from: rule.email?.from || '',
        subject: rule.email?.subject || '',
        content: rule.email?.text || '',
        attachVariables: rule.email?.attachVariables || false,
        recipient: generateRecipientFromRuleGET(rule) as RecipientWithoutCaller,
      };
    case AssignmentTypes.WEBHOOK:
    case AssignmentTypes.WORKBENCH:
      return {
        type: rule.assignmentType,
        url: rule.url || '',
        reqMethod: rule.reqMethod || RequestMethod.GET,
        forwardWebhookResponse: rule.forwardWebhookResponse || false,
        forwardUrl: rule.forwardUrl || '',
        reqHeaders: rule.reqHeaders || [],
        announcementDelay: rule.announcementDelay,
        payload: generatePayloadFromRuleGET(rule),
      };
    case AssignmentTypes.EVENT:
      return {
        type: rule.assignmentType,
        url: rule.url || '',
        reqMethod: rule.reqMethod || RequestMethod.GET,
        forwardWebhookResponse: rule.forwardWebhookResponse || false,
        forwardUrl: rule.forwardUrl || '',
        reqHeaders: rule.reqHeaders || [],
        connectorId: rule.assignmentEvent || '',
        announcementDelay: rule.announcementDelay,
        payload: generatePayloadFromRuleGET(rule),
      };
    case AssignmentTypes.TASK_STATUS_CHANGE:
      return {
        type: rule.assignmentType,
        taskExternalId: rule.taskExternalId || '',
        changeTasksStatusTo: rule.changeTasksStatusTo || TaskStatus.DONE,
      };
    case AssignmentTypes.DEVICE_LOCATION_CHANGE: {
      const action = rule.action as RuleDeviceLocationChangeAction;
      return {
        type: rule.assignmentType,
        deviceIds: action.deviceIds || [],
        locationId: action.locationId || null,
      };
    }
    case AssignmentTypes.DATABASE:
      return {
        type: rule.assignmentType,
        databaseId: rule.database || '',
        databaseAction: rule.databaseAction || DatabaseAction.CREATE_OR_UPDATE,
        itemId: rule.databaseUniqueID || '',
        payload:
          rule.databasePayload?.map((e) => ({
            ...e,
            // Somehow the backend does not send these data
            // Here we set temporary "empty" values.
            // This object is only used in the DatabaseForm where
            // these data are set with latest values
            slug: '',
            name: '',
            type: DatabaseColumnTypes.INTEGER,
          })) || [], // this complex field is not validated, it may cause crash.
      };
    case AssignmentTypes.FUNCTION: {
      const action = rule.action as RuleFunctionActionNetwork;
      return {
        type: rule.assignmentType,
        functionId: action.functionId || undefined,
        forwardReturnValue: action.forwardReturnValue || false,
        forwardUrl: action.forwardUrl || '',
        payload: {
          useCustomPayload: action.useCustomPayload || false,
          reqPayloads: action.reqPayload || [],
        },
        announcementDelay: rule.announcementDelay,
      };
    }
    case AssignmentTypes.SKILLS: {
      const action = rule.action as RuleSkillsAction;
      return {
        type: rule.assignmentType,
        userId: action.userId || '',
        skillsToAdd: action.skillsToAdd || [],
        skillsToRemove: action.skillsToRemove || [],
      };
    }
    case AssignmentTypes.ROLES: {
      const action = rule.action as RuleRolesAction;
      return {
        type: rule.assignmentType,
        userId: action.userId || '',
        rolesToAdd: action.rolesToAdd || [],
        rolesToRemove: action.rolesToRemove || [],
      };
    }
    default:
      return rule.action ? (rule.action as RuleAction) : undefined;
  }
}

const normalizeTaskAnnouncementFormValues = (announcement: TaskAnnouncement | undefined): TaskAnnouncement => {
  if (!announcement) {
    const notificationAnnouncement: NotificationTaskAnnouncement = {
      type: TaskAnnouncementType.NOTIFICATION,
      notificationType: NotificationTypes.ONLY_AVAILABLE,
    };
    return notificationAnnouncement;
  }

  switch (announcement.type) {
    case TaskAnnouncementType.NOTIFICATION: {
      const notificationAnnouncement: NotificationTaskAnnouncement = {
        type: TaskAnnouncementType.NOTIFICATION,
        notificationType: announcement.notificationType || NotificationTypes.ONLY_AVAILABLE,
      };
      return notificationAnnouncement;
    }

    case TaskAnnouncementType.AUTO_ACCEPT: {
      const autoAcceptAnnouncement: AutoAcceptTaskAnnouncement = {
        type: TaskAnnouncementType.AUTO_ACCEPT,
        currentTaskAction: announcement.currentTaskAction,
      };
      return autoAcceptAnnouncement;
    }

    case TaskAnnouncementType.AWARENESS:
    case TaskAnnouncementType.NONE: {
      const taskAnnouncement: NoneTaskAnnouncement | AwarenessTaskAnnouncement = {
        type: announcement.type,
      };
      return taskAnnouncement;
    }
  }
};

export function normalizeRule(rule: RuleGET): Rule {
  const action = generateRuleActionFromRuleGET(rule);
  // See types file, the backend does not send consistent data.
  // For a GET ALL, the object has the name populated.
  // For a GET BY ID, we only have the eventId as a string.
  let normalizedRuleEventId: string;
  if (typeof rule.event === 'string') {
    normalizedRuleEventId = rule.event;
  } else {
    normalizedRuleEventId = rule.event?._id || '';
  }
  return {
    id: rule._id,
    createdAt: rule.meta.createdAt,
    updatedAt: rule.meta.updatedAt,
    triggeredAt: rule.triggeredAt,
    name: rule.name,
    action,
    description: rule.description,
    active: rule.active,
    projectId: rule.project,
    conditions: rule.filter?.length ? rule.filter : [[]],
    noAction: !!action,
    trigger: generateRuleTriggerFromRuleGET(rule.trigger, normalizedRuleEventId),
    links: rule.links,
  };
}

function generateRecipientPOSTData(
  recipient: Recipient,
  mode: 'task' | 'message',
  globalLocationSettings?: GlobalLocationSettings,
): RecipientPOST {
  let recipientPOSTData: RecipientPOST;

  switch (recipient.type) {
    case RecipientTypes.ROLES:
      recipientPOSTData = {
        roles: recipient.roles,
        isUserMessage: false,
        isUserTask: false,
        isAssignedUserVar: false,
        selfinstruction: false,
      };
      break;
    case RecipientTypes.USER:
      recipientPOSTData = {
        assignedUser: recipient.assignedUser,
        isAssignedUserVar: false,
        selfinstruction: false,
      };
      if (mode === 'task') {
        recipientPOSTData.isUserTask = true;
      } else if (mode === 'message') {
        recipientPOSTData.isUserMessage = true;
      }
      break;
    case RecipientTypes.VARIABLES:
      recipientPOSTData = {
        assignedUserVar: splitVariables(recipient.variables),
        isAssignedUserVar: true,
        selfinstruction: false,
      };
      if (mode === 'task') {
        recipientPOSTData.isUserTask = true;
      } else if (mode === 'message') {
        recipientPOSTData.isUserMessage = true;
      }
      break;
    case RecipientTypes.CALLER:
      recipientPOSTData = {
        isUserMessage: false,
        isUserTask: false,
        isAssignedUserVar: false,
        selfinstruction: true,
      };
      break;
  }

  if (globalLocationSettings) {
    if (globalLocationSettings.isVariable) {
      recipientPOSTData.notifyLocationIsVar = true;
      recipientPOSTData.notifyLocationVar = globalLocationSettings.locationIdVariable;
    } else {
      recipientPOSTData.notifyLocationIsVar = false;
      recipientPOSTData.notifyLocations = globalLocationSettings.locationSettings.map((locationSetting) => {
        const updatedLocationSetting: LocationSetting & {
          level?: string;
          levelId?: string;
        } = locationSetting;
        updatedLocationSetting.level = locationSetting.levelId;
        // the compiler doesn't understand that the property has been deleted from the variable, so its type changed
        // @ts-ignore -- to be refactored
        delete updatedLocationSetting.levelId;
        return updatedLocationSetting;
      });
    }
  }

  return recipientPOSTData;
}

function normalizeTaskStatusChangeFormValues(
  baseRule: RulePOSTBase,
  values: RuleTaskStatusChangeAction,
): RuleTaskStatusChangePOST {
  return {
    ...baseRule,
    assignmentType: AssignmentTypes.TASK_STATUS_CHANGE,
    taskExternalId: values.taskExternalId,
    changeTasksStatusTo: values.changeTasksStatusTo,
  };
}

function normalizeMessageFormValues(baseRule: RulePOSTBase, values: RuleMessageAction): RuleMessagePOST {
  return {
    ...baseRule,
    assignmentType: AssignmentTypes.MESSAGE,
    ...generateRecipientPOSTData(values.recipient, 'message', values.globalLocationSettings),
    message: values.message,
    timeout: values.timeout?.seconds ? { seconds: values.timeout.seconds } : null,
    announcementDelay: values.announcementDelay || null,
  };
}

function normalizeEmailFormValues(baseRule: RulePOSTBase, values: RuleEmailAction): RuleEmailPOST {
  return {
    ...baseRule,
    ...generateRecipientPOSTData(values.recipient, 'task'),
    assignmentType: AssignmentTypes.EMAIL,
    email: {
      from: values.from,
      subject: values.subject,
      text: values.content,
      attachVariables: values.attachVariables,
    },
  };
}

function normalizeRuleTaskAction(
  baseRule: RulePOSTBase,
  values: RuleTaskAction,
  assignmentType: AssignmentTypes.TASK | AssignmentTypes.DELEGATE,
): RuleTaskPOST {
  return {
    ...baseRule,
    assignmentType,
    ...generateRecipientPOSTData(values.recipient, 'task', values.globalLocationSettings),
    assignment: {
      skills: values.skills,
    },
    rejectReasons: values.task.rejectReasons.map((rejectReason) => ({
      text: rejectReason,
    })),
    task: values.task,
    initialStepObjectId: values.taskAdvancedSettings.initialStepObjectId || null,
    taskExternalId: values.taskAdvancedSettings.taskExternalId,
    announcementDelay: values.taskAdvancedSettings.announcementDelay,
    suspendTaskWhenNoUserAvailable: values.taskAdvancedSettings.suspendTaskWhenNoUserAvailable,
    suspendTaskWhenNoUserIsAssignable: values.taskAdvancedSettings.suspendTaskWhenNoUserIsAssignable,
    reannouncePeriod: values.taskAdvancedSettings.reannouncePeriod,
    ttl: values.taskAdvancedSettings.ttl,
    dueInSeconds: values.taskAdvancedSettings.dueInSeconds,
    autostart: values.taskAdvancedSettings.autostart,
    notificationForce: values.taskAdvancedSettings.notificationForce,
  };
}

function normalizeWorkinstructionFormValues(
  baseRule: RulePOSTBase,
  values: RuleWorkinstructionAction,
): RuleWorkinstructionPOST {
  return {
    ...baseRule,
    assignmentType: AssignmentTypes.WORKINSTRUCTION,
    ...generateRecipientPOSTData(values.recipient, 'task', values.globalLocationSettings),
    assignment: {
      skills: values.skills,
    },
    task: values.task,
    workinstructions: values.workinstructionId,
    initialStepObjectId: values.taskAdvancedSettings.initialStepObjectId || null,
    taskExternalId: values.taskAdvancedSettings.taskExternalId,
    announcementDelay: values.taskAdvancedSettings.announcementDelay || null,
    reannouncePeriod: values.taskAdvancedSettings.reannouncePeriod || null,
    notificationForce: values.taskAdvancedSettings.notificationForce,
    suspendTaskWhenNoUserAvailable: values.taskAdvancedSettings.suspendTaskWhenNoUserAvailable,
    suspendTaskWhenNoUserIsAssignable: values.taskAdvancedSettings.suspendTaskWhenNoUserIsAssignable,
    ttl: values.taskAdvancedSettings.ttl || null,
    dueInSeconds: values.taskAdvancedSettings.dueInSeconds || null,
    autostart: values.taskAdvancedSettings.autostart,
    rejectReasons: values.task.rejectReasons.map((rejectReason) => ({
      text: rejectReason,
    })),
    connectedTo: values.connectedTo || [],
  };
}

const normalizeRequestPayload = (payload: Payload): PayloadPOST => {
  let payloadData: PayloadPOST = { useCustomPayload: false };
  if (payload.useCustomPayload) {
    payloadData = {
      useCustomPayload: true,
      reqPayloadFormat: payload.format,
      reqPayloadRootName: payload.rootName,
      reqPayload: payload.reqPayloads,
    };
  }
  return payloadData;
};

const normalizeRequestActionData = (values) => {
  const payloadData: PayloadPOST = normalizeRequestPayload(values.payload);
  return {
    url: values.url,
    announcementDelay: values.announcementDelay || null,
    reqMethod: values.reqMethod,
    reqHeaders: values.reqHeaders,
    forwardWebhookResponse: values.forwardWebhookResponse,
    forwardUrl: values.forwardUrl,
    ...payloadData,
  };
};

const normalizeWorkbenchFormValues = (baseRule: RulePOSTBase, values: RuleRequestAction): RuleRequestPOST => {
  const requestActionData = normalizeRequestActionData(values);
  return {
    ...baseRule,
    assignmentType: AssignmentTypes.WORKBENCH,
    ...requestActionData,
  };
};

const normalizeWebhookActionFormValues = (baseRule: RulePOSTBase, values: RuleWebhookAction): RuleWebhookPOST => {
  const requestActionData = normalizeRequestActionData(values);
  return {
    ...baseRule,
    assignmentType: AssignmentTypes.WEBHOOK,
    assignmentEvent: values.connectorId, // put here connectorId if webhook url matches connector url in the system
    ...requestActionData,
  };
};

const normalizeEventActionFormValues = (baseRule: RulePOSTBase, values: RuleEventAction): RuleEventPOST => {
  const requestActionData = normalizeRequestActionData(values);
  return {
    ...baseRule,
    assignmentType: AssignmentTypes.EVENT,
    assignmentEvent: values.connectorId, // connectorId
    ...requestActionData,
  };
};

function normalizeDatabaseFormValues(baseRule: RulePOSTBase, values: RuleDatabaseAction): RuleDatabasePOST {
  return {
    ...baseRule,
    assignmentType: AssignmentTypes.DATABASE,
    database: values.databaseId,
    databaseAction: values.databaseAction,
    databaseUniqueID: values.itemId,
    databasePayload: values.payload,
  };
}

function normalizeFunctionFormValues(baseRule: RulePOSTBase, values: RuleFunctionAction): RuleFunctionPOST {
  let payloadData: {
    useCustomPayload: boolean;
    reqPayload?: RequestPayload[];
  } = { useCustomPayload: false };
  if (values.payload.useCustomPayload) {
    payloadData = {
      useCustomPayload: true,
      reqPayload: values.payload.reqPayloads,
    };
  }
  return {
    ...baseRule,
    assignmentType: AssignmentTypes.FUNCTION,
    announcementDelay: values.announcementDelay || null,
    action: {
      type: AssignmentTypes.FUNCTION,
      ...payloadData,
      forwardReturnValue: values.forwardReturnValue,
      forwardUrl: values.forwardUrl,
      functionId: values.functionId ?? '',
    },
  };
}

interface RuleWizardToRulePOST {
  projectId: string;
  name: string;
  description: string;
  trigger: Trigger;
  conditions: SelectedCondition[][];
  assignmentType: AssignmentTypes;
  assignmentValues:
    | RuleTaskStatusChangeAction
    | RuleDeviceLocationChangeAction
    | RuleMessageAction
    | RuleEmailAction
    | RuleTaskAction
    | RuleWorkinstructionAction
    | RuleRequestAction
    | RuleDatabaseAction
    | RuleFunctionAction
    | RuleSkillsAction
    | RuleRolesAction
    | RuleMqttPublishAction
    | RuleMqttSubscribeAction
    | RuleUpdateTaskVariablesAction;
  active: boolean;
}
// eslint-disable-next-line consistent-return -- to be refactored as default case must be added to switch
export function convertRuleWizardToRulePOST({
  projectId,
  name,
  description,
  trigger,
  conditions,
  assignmentType,
  assignmentValues,
  active,
}: RuleWizardToRulePOST): RulePOST {
  const baseRule: RulePOSTBase = {
    assignmentType,
    trigger: {
      type: trigger.type,
      source: trigger.source,
    },
    project: projectId,
    name,
    description,
    noAction: false, // TODO: make this dynamic
    filter: conditions,
    active,
  };

  if (trigger.type === TriggerType.EXTERNAL_EVENT) {
    baseRule.event = trigger.resourceId;
    baseRule.trigger.resourceId = trigger.resourceId;
  } else if (trigger.schedule) {
    baseRule.trigger = {
      ...baseRule.trigger,
      schedule: trigger.schedule,
    };
  }

  switch (assignmentType) {
    case AssignmentTypes.TASK_STATUS_CHANGE:
      return normalizeTaskStatusChangeFormValues(baseRule, assignmentValues as RuleTaskStatusChangeAction);
    case AssignmentTypes.MESSAGE:
      return normalizeMessageFormValues(baseRule, assignmentValues as RuleMessageAction);
    case AssignmentTypes.EMAIL:
      return normalizeEmailFormValues(baseRule, assignmentValues as RuleEmailAction);
    case AssignmentTypes.TASK:
    case AssignmentTypes.DELEGATE:
      return normalizeRuleTaskAction(baseRule, assignmentValues as RuleTaskAction, assignmentType);
    case AssignmentTypes.WORKINSTRUCTION:
      return normalizeWorkinstructionFormValues(baseRule, assignmentValues as RuleWorkinstructionAction);
    case AssignmentTypes.WORKBENCH:
      return normalizeWorkbenchFormValues(baseRule, assignmentValues as RuleRequestAction);
    case AssignmentTypes.WEBHOOK:
      return normalizeWebhookActionFormValues(baseRule, assignmentValues as RuleWebhookAction);
    case AssignmentTypes.EVENT:
      return normalizeEventActionFormValues(baseRule, assignmentValues as RuleEventAction);
    case AssignmentTypes.DATABASE:
      return normalizeDatabaseFormValues(baseRule, assignmentValues as RuleDatabaseAction);
    case AssignmentTypes.FUNCTION:
      return normalizeFunctionFormValues(baseRule, assignmentValues as RuleFunctionAction);
    case AssignmentTypes.DEVICE_LOCATION_CHANGE:
      return {
        ...baseRule,
        assignmentType: AssignmentTypes.DEVICE_LOCATION_CHANGE,
        action: assignmentValues as RuleDeviceLocationChangeAction,
      };
    case AssignmentTypes.ROLES:
      return {
        ...baseRule,
        assignmentType: AssignmentTypes.ROLES,
        action: assignmentValues as RuleRolesAction,
      };
    case AssignmentTypes.SKILLS:
      return {
        ...baseRule,
        assignmentType: AssignmentTypes.SKILLS,
        action: assignmentValues as RuleSkillsAction,
      };
    case AssignmentTypes.MQTT_PUBLISH:
      return {
        ...baseRule,
        assignmentType: AssignmentTypes.MQTT_PUBLISH,
        action: assignmentValues as RuleMqttPublishAction,
      };
    case AssignmentTypes.MQTT_SUBSCRIBE:
      return {
        ...baseRule,
        assignmentType: AssignmentTypes.MQTT_SUBSCRIBE,
        action: assignmentValues as RuleMqttSubscribeAction,
      };
    case AssignmentTypes.UPDATE_TASK_VARIABLES:
      return {
        ...baseRule,
        assignmentType: AssignmentTypes.UPDATE_TASK_VARIABLES,
        action: assignmentValues as RuleUpdateTaskVariablesAction,
      };
  }
}
