import { IPaginatedQueryParams } from '@workerbase/api/http/common';
import { ApiResponse, ApiResponsePaginated } from '@workerbase/types/Response';
import {
  DocumentByResourceItemQuery,
  DocumentCreateBody,
  DocumentFormResultResponse,
  DocumentInboxPopulatedResponse,
  DocumentResponse,
  DocumentUpdateBody,
  DocumentVersionCloneBody,
  DocumentVersionResponse,
  DocumentVersionUpdateBody,
  InboxUploadResponse,
} from '@workerbase/api/http/document';
import { DocumentPopulated } from '@workerbase/domain/document';
import { cloneDeep } from 'lodash';
import { api } from './api';

const DOCUMENTS_ENDPOINT = '/api/v1/documents';
const DOCUMENTS_INBOX_ENDPOINT = `${DOCUMENTS_ENDPOINT}/inbox`;
const DOCUMENTS_INBOX_GDRIVE_ENDPOINT = `${DOCUMENTS_ENDPOINT}/inbox/gdrive`;

export const uploadInboxDocument = async (mediaId: string) => {
  const { data } = await api.post<ApiResponse<InboxUploadResponse>>(DOCUMENTS_INBOX_ENDPOINT, { mediaId });

  return data;
};

export const uploadInboxGDriveDocument = async (options: { fileId: string; accessToken: string }) => {
  const { data } = await api.post<ApiResponse<InboxUploadResponse>>(DOCUMENTS_INBOX_GDRIVE_ENDPOINT, options);

  return data;
};

export const deleteInboxDocument = async (fileId: string) => api.delete(`${DOCUMENTS_INBOX_ENDPOINT}/${fileId}`);

export const getInboxDocuments = async (params: IPaginatedQueryParams) => {
  const { data } = await api.get<ApiResponsePaginated<DocumentInboxPopulatedResponse[]>>(DOCUMENTS_INBOX_ENDPOINT, {
    params,
  });

  return data;
};

export const createDocument = async (body: DocumentCreateBody) => {
  const { data } = await api.post<ApiResponse<DocumentResponse>>(`${DOCUMENTS_ENDPOINT}`, body);

  return data;
};

export const updateDocument = async (documentId: string, data: DocumentUpdateBody) => {
  const { data: responseData } = await api.put<ApiResponse<DocumentUpdateBody>>(
    `${DOCUMENTS_ENDPOINT}/${documentId}`,
    data,
  );

  return responseData;
};

export const getDocument = async (documentId: string) => {
  const { data } = await api.get<ApiResponse<DocumentResponse>>(`${DOCUMENTS_ENDPOINT}/${documentId}`);

  return data.data;
};

export const getDocuments = async (params: DocumentByResourceItemQuery) => {
  const { data } = await api.get<ApiResponse<DocumentPopulated[]>>(DOCUMENTS_ENDPOINT, { params });

  return data.data;
};

export const getDocumentVersions = async (documentId: string) => {
  const { data } = await api.get<ApiResponse<DocumentVersionResponse[]>>(
    `${DOCUMENTS_ENDPOINT}/${documentId}/versions`,
  );

  return data.data;
};

export const publishDocumentVersion = async (documentId: string, versionId: string) => {
  await api.post<ApiResponse<void>>(`${DOCUMENTS_ENDPOINT}/${documentId}/versions/${versionId}/publish`);
  return true;
};

export interface VersionFormBody {
  mediaId: string;
  name: string;
}

export const uploadDocumentVersion = async (documentId: string, body: VersionFormBody) => {
  const { data } = await api.post<ApiResponse<DocumentVersionResponse>>(
    `${DOCUMENTS_ENDPOINT}/${documentId}/versions`,
    body,
  );

  return data.data;
};

export const cloneDocumentVersion = async (documentId: string, versionId: string, body: DocumentVersionCloneBody) => {
  const { data } = await api.post<ApiResponse<DocumentVersionResponse>>(
    `${DOCUMENTS_ENDPOINT}/${documentId}/versions/${versionId}/clone`,
    body,
  );

  return data.data;
};

export const updateDocumentVersion = async (documentId: string, versionId: string, body: DocumentVersionUpdateBody) => {
  // BE will break if send _id in documentBuilder steps.
  const getPrunedDocumentBuilder = (document: DocumentVersionUpdateBody | undefined) => {
    const prunedDocumentBuilder = cloneDeep(document);

    if (prunedDocumentBuilder?.steps) {
      prunedDocumentBuilder.steps.forEach((step) => {
        delete step._id;
      });
    }

    return prunedDocumentBuilder;
  };

  const { data } = await api.put<ApiResponse<DocumentVersionResponse>>(
    `${DOCUMENTS_ENDPOINT}/${documentId}/versions/${versionId}`,
    getPrunedDocumentBuilder(body),
  );

  return data.data;
};

export const getFinderFormResult = async (
  formId: string,
  formResultId: string,
): Promise<DocumentFormResultResponse> => {
  const { data } = await api.get<ApiResponse<DocumentFormResultResponse>>(
    `${DOCUMENTS_ENDPOINT}/${formId}/results/${formResultId}`,
  );

  return data.data;
};
